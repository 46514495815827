<template>
  <AppHeader class="headertop" />
  <div class="content">
    <div class="orderlist" v-if="billInfo.length > 0">
      <div class="orderlist-1">
        <div class="orderlist-1-g">商品信息</div>
        <div class="orderlist-1-d">单价</div>
        <div class="orderlist-1-a">数量</div>
        <div class="orderlist-1-s">小计</div>
        <div class="orderlist-1-c">操作</div>
      </div>
      <div class="orderlist-2" v-for="(item, index) in billInfo">
        <div class="orderlist-2-1">
          <img
            src="./img/check.png"
            class="orderlist-i"
            @click="checked(index)"
            v-if="item.ischeck"
          />
          <img
            src="./img/ischeck.png"
            class="orderlist-i"
            @click="checked(index)"
            v-else
          />
          <div @click="clickGoodsDetail(item)" class="goods-detail">
            <img :src="item.picture" class="orderlist-img" />
            <div class="orderlist-t">{{ item.productName }}</div>
            <div class="orderlist-s">{{ item.configType }}</div>
            <div class="orderlist-m">￥{{ item.price }}</div>
          </div>
          <img
            src="./img/reduce.png"
            class="orderlist-r"
            @click="reduce(index)"
          />
          <div class="orderlist-c">{{ item.productAmount }}</div>
          <img src="./img/plus.png" class="orderlist-p" @click="plus(index)" />
          <div class="orderlist-a">
            ￥{{ Math.round(item.price * item.productAmount * 100) / 100 }}
          </div>
          <div class="orderlist-l" @click="querysetting(item)">查看配置</div>
          <div class="orderlist-d" @click="deleteshop(item)">删除商品</div>
        </div>
        <div class="center"></div>
        <div v-if="item.customerMaterials">
          <div class="orderlist-4" v-for="custome in item.customerMaterials">
            <div class="orderlist-4-1">客制</div>
            <div class="orderlist-4-2">{{ custome.materialName }}</div>
            <div class="orderlist-4-3">¥{{ custome.price }}</div>
            <div class="orderlist-4-4">x{{ item.productAmount }}</div>
          </div>
        </div>
        <div class="floor"></div>
      </div>
      <div class="temp"></div>
      <div class="orderlist-3">
        <img
          src="./img/ischeck.png"
          class="orderlist-3-1"
          @click="totaled"
          v-if="selectt"
        />
        <img
          src="./img/check.png"
          class="orderlist-3-1"
          @click="totaled"
          v-else
        />
        <div class="orderlist-3-2">全选</div>
        <div class="orderlist-3-3">
          已选择<span style="color: #ce1200">{{ piece }}</span
          >件
        </div>
        <div class="orderlist-3-4">合计：</div>
        <div class="orderlist-3-5">￥{{ total }}</div>
        <div class="orderlist-3-6" @click="goAmount">去结算({{ piece }})</div>
      </div>
    </div>
    <div class="empty" v-else>
      <img src="./img//empty.png" class="empty-1" />
      <div class="empty-2">购物车空空如也，去逛逛吧~</div>
      <div class="empty-3" @click="goshopping">马上去购物</div>
    </div>
  </div>
  <div class="dialog-list">
    <el-dialog
      style="width: 62%; height: 76%; --el-dialog-margin-top: 10vh"
      v-model="popUpList"
    >
      <div class="border-content">
        <el-table
          :data="orderMaterialVOSMaterial"
          height="32.5rem"
          stripe
          style="width: 100%"
        >
          <el-table-column prop="materialName" label="物料名称" width="380" />
          <el-table-column prop="amount" label="数量" align="center" />
          <el-table-column prop="typeName" label="物料类型" align="center" />
          <el-table-column
            prop="materialCode"
            label="物料编码"
            align="center"
          />
        </el-table>
        <div class="qOk" @click="popUpList = false">确定</div>
      </div>
    </el-dialog>
  </div>
  <HomeBackup />
</template>

<script setup>
import { onMounted, ref } from "vue";
import AppHeader from "@/components/AppHeader";
import HomeBackup from "../home/components/HomeBackup.vue";
import { deleteshopId, shopList, shopSetting } from "@/api/shop/index";
import { useRoute, useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";

const route = useRoute();
const sellNum = ref("");
const total = ref(0);
const piece = ref(0);
const selectt = ref(true);
const billInfo = ref([]);
const tapPane = ref([]);
const orderMaterialVOSMaterial = ref([]);
const shopListInfo = ref([]);
const router = useRouter();
const popUpList = ref(false);
const goshopping = () => {
  router.push("/commercialProduct");
};
const deleteshop = async (item) => {
  let res = await ElMessageBox.confirm("是否确认删除该商品？", {
    confirmButtonText: "删除",
    cancelButtonText: "取消",
    type: "warning",
  }).catch((error) => {
    console.log(error);
  });
  if (res == "confirm") {
    let id = item.id;
    await deleteshopId(id);
    orderList();
    ElMessage({
      type: "success",
      message: "删除成功",
    });
  }
};
const reduce = (num) => {
  if (billInfo.value[num].productAmount == 1) {
    billInfo.value[num].productAmount == 1;
  } else {
    billInfo.value[num].productAmount = billInfo.value[num].productAmount - 1;
  }
  totalsum();
};
const plus = (num) => {
  if (billInfo.value[num].productAmount == 10) {
    billInfo.value[num].productAmount == 10;
  } else {
    billInfo.value[num].productAmount = billInfo.value[num].productAmount + 1;
  }
  totalsum();
};
const goAmount = () => {
  if (piece.value == 0) {
    ElMessage({
      type: "error",
      message: "请先选择商品",
    });
  } else {
    const temp = JSON.stringify(billInfo.value);
    router.push({
      path: "/configordersettingcopy1",
      query: {
        item: temp,
        count: piece.value,
        total: total.value,
      },
    });
  }
};
const querysetting = (val) => {
  shopSetting(val.id).then((res) => {
    orderMaterialVOSMaterial.value = res.data;
    popUpList.value = true;
  });
};
const orderList = () => {
  shopList().then((res) => {
    if (res.data) {
      billInfo.value = res.data;
      billInfo.value.forEach((element) => {
        element.ischeck = false;
      });
    } else {
      billInfo.value = [];
    }
  });
};
const totalsum = () => {
  total.value = 0;
  piece.value = 0;
  billInfo.value.forEach((item) => {
    if (item.ischeck) {
      total.value =
        Math.round((total.value + item.price * item.productAmount) * 100) / 100;
      piece.value = piece.value + item.productAmount;
    }
  });
  let data = billInfo.value.every((item) => {
    return item.ischeck;
  });
  if (data) {
    selectt.value = false;
  } else {
    selectt.value = true;
  }
};
const totaled = () => {
  if (selectt.value) {
    billInfo.value.forEach((item) => {
      item.ischeck = true;
    });
    totalsum();
    selectt.value = false;
  } else {
    billInfo.value.forEach((item) => {
      item.ischeck = false;
    });
    total.value = 0;
    selectt.value = true;
  }
};
const checked = (inx) => {
  if (billInfo.value[inx].ischeck) {
    billInfo.value[inx].ischeck = false;
    totalsum();
  } else {
    billInfo.value[inx].ischeck = true;
    totalsum();
  }
};

// 点击跳转商品详情
const clickGoodsDetail = (item) => {
  if (item.machineType == 3 || item.machineType == 5 || item.machineType == 6) {
    router.push({
      name: "detailsGoodsNew",
      query: {
        id: item.productId,
        houseNo: 1,
      },
    });
  } else {
    router.push({
      name: "detailsGoods",
      query: {
        id: item.productId,
        houseNo: 1,
      },
    });
  }
};
onMounted(() => {
  orderList();
});
</script>

<style lang="less" scoped>
.headertop {
  background-color: rgba(#000, 0.5);
  position: fixed;
  top: 0px;
  width: 100%;
  height: 70px;
  z-index: 101;
}
.content {
  width: 1920px;
  background: #f7f7f7;
  min-height: 900px;
  display: flex;
  .empty {
    width: 1296px;
    height: 580px;
    float: left;
    background: #ffffff;
    margin: 110px 0 222px 312px;
    .empty-1 {
      display: block;
      margin: 0 auto;
      margin-top: 100px !important;
    }
    .empty-2 {
      width: 233px;
      height: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 18px;
      color: #999999;
      margin-left: 532px;
      margin-top: 8px;
    }
    .empty-3 {
      width: 156px;
      height: 48px;
      background: #ce1200;
      border-radius: 24px;
      text-align: center;
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 15px;
      line-height: 48px;
      color: #ffffff;
      margin: 32px 0 0 570px;
      cursor: pointer;
    }
  }
  .orderlist {
    width: 1296px;
    float: left;
    margin: 110px 0 0 312px;
    .temp {
      width: 1920px;
      height: 110px;
    }
    .orderlist-1 {
      width: 1296px;
      height: 64px;
      background: #ffffff;
      position: relative;
      display: flex;
      .orderlist-1-g {
        position: absolute;
        top: 22px;
        left: 106px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 15px;
        color: #333333;
      }
      .orderlist-1-d {
        position: absolute;
        top: 22px;
        left: 636px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 15px;
        color: #333333;
      }
      .orderlist-1-a {
        position: absolute;
        top: 22px;
        left: 816px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 15px;
        color: #333333;
      }
      .orderlist-1-s {
        position: absolute;
        top: 22px;
        left: 996px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 15px;
        color: #333333;
      }
      .orderlist-1-c {
        position: absolute;
        top: 22px;
        left: 1176px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 15px;
        color: #333333;
      }
    }
    .orderlist-2 {
      width: 1296px;
      background: #ffffff;
      margin-top: 12px;
      .orderlist-2-1 {
        position: relative;
        top: 24px;
        width: 1192px;
        height: 80px;
        margin-left: 30px;
        .orderlist-i {
          position: absolute;
          top: 29px;
          cursor: pointer;
        }
        .goods-detail {
          position: relative;
          left: 76px;
          width: 600px;
          height: 100%;
          cursor: pointer;
          .orderlist-img {
            position: absolute;
            width: 80px;
            height: 80px;
          }
          .orderlist-t {
            width: 355px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 15px;
            color: #333333;
            line-height: 22px;
            position: absolute;
            top: 14px;
            left: 100px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .orderlist-s {
            height: 24px;
            width: 240px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 14px;
            padding: 0 10px;
            color: #666666;
            line-height: 24px;
            background: #f2f2f2;
            border-radius: 12px;
            position: absolute;
            top: 42px;
            left: 100px;
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .orderlist-m {
            position: absolute;
            top: 28px;
            left: 507px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 18px;
            color: #666666;
          }
        }

        .orderlist-r {
          position: absolute;
          top: 26px;
          left: 757px;
          cursor: pointer;
        }
        .orderlist-c {
          width: 32px;
          height: 28px;
          position: absolute;
          top: 26px;
          left: 785px;
          line-height: 28px;
          text-align: center;
        }
        .orderlist-p {
          position: absolute;
          top: 26px;
          left: 817px;
          cursor: pointer;
        }
        .orderlist-a {
          position: absolute;
          top: 28px;
          left: 942px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 18px;
          color: #ce1200;
        }
        .orderlist-l {
          position: absolute;
          top: 18px;
          left: 1132px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 14px;
          color: #999999;
          cursor: pointer;
        }
        .orderlist-l:hover {
          position: absolute;
          top: 18px;
          left: 1132px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 14px;
          color: #ce1200;
          cursor: pointer;
        }
        .orderlist-d {
          position: absolute;
          top: 48px;
          left: 1132px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 14px;
          color: #999999;
          cursor: pointer;
        }
        .orderlist-d:hover {
          position: absolute;
          top: 48px;
          left: 1132px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 14px;
          color: #ce1200;
          cursor: pointer;
        }
      }
      .center {
        width: 1060px;
        height: 45px;
      }
      .orderlist-4 {
        height: 64px;
        width: 1060px;
        margin-left: 206px;
        border-top: 1px solid #f2f2f2;
        .orderlist-4-1 {
          width: 44px;
          height: 24px;
          background: rgba(255, 105, 0, 0.1);
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 11px;
          color: #ce1200;
          text-align: center;
          line-height: 24px;
          position: relative;
          top: 20px;
        }
        .orderlist-4-2 {
          width: 295px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 13px;
          color: #333333;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          position: relative;
          left: 60px;
        }
        .orderlist-4-3 {
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 18px;
          color: #666666;
          position: relative;
          left: 412px;
          bottom: 20px;
        }
        .orderlist-4-4 {
          position: relative;
          left: 616px;
          bottom: 44px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 16px;
          color: #666666;
        }
      }
      .floor {
        width: 1060px;
        height: 16px;
      }
    }
    .orderlist-3 {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 9;
      width: 1920px;
      height: 100px;
      background: #ffffff;
      .orderlist-3-1 {
        position: absolute;
        left: 342px;
        top: 42px;
        cursor: pointer;
      }
      .orderlist-3-2 {
        width: 30px;
        height: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 15px;
        color: #333333;
        position: absolute;
        left: 378px;
        top: 42px;
      }
      .orderlist-3-3 {
        position: absolute;
        left: 448px;
        top: 42px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 15px;
        color: #666666;
      }
      .orderlist-3-4 {
        width: 42px;
        height: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #ce1200;
        position: absolute;
        right: 669px;
        top: 43px;
      }
      .orderlist-3-5 {
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 28px;
        color: #ce1200;
        position: absolute;
        left: 1253px;
        top: 28px;
      }
      .orderlist-3-6 {
        width: 156px;
        height: 48px;
        background: #ce1200;
        border-radius: 24px;
        position: absolute;
        right: 312px;
        top: 26px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 15px;
        text-align: center;
        color: #ffffff;
        line-height: 48px;
        cursor: pointer;
      }
    }
  }
}
.dialog-list {
  ::v-deep .el-dialog__title {
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
    padding-bottom: 0;
    margin-right: 0;
    padding-top: 52px;
    margin-left: 80px;
  }
  ::v-deep .el-dialog__body {
    padding: 0;
  }
  ::v-deep .el-dialog__headerbtn {
    position: absolute;
    top: 39px;
    right: 58px;
    .el-dialog__close {
      font-size: 20px;
      color: #999;
    }
    .el-dialog__close:hover {
      color: #ce1200;
    }
  }
  .border-content {
    width: 1095px;
    margin-left: 65px;
    //margin-top: 36;
    .qOk {
      width: 180px;
      height: 40px;
      line-height: 40px;
      background: #ce1200;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      margin: 0 auto;
      margin-top: 20px;
      cursor: pointer;
    }
  }
}
</style>
